export default class RegionPicker {

    constructor(elem, onChange) {
        const $elem = this.$elem = $(elem);
        const $input = this.$input = $elem.find('input');

        if ($input.attr("type") === "radio") {
            $input.on('change', e => {
                this.selectRegion(e.currentTarget.value, ((window.innerWidth < 768) ? e.currentTarget.parentElement.getAttribute("data-mobile") : null) || e.currentTarget.parentElement.textContent);
            });
        } else {
            $input.on('change keydown keyup', e => {
                if (e.which === 40) { //down
                    if (e.type !== 'keydown') return;
                    this.highlightRegionHint((this.highlightedRegionHint >= $elem.find('.options li').length - 1 ? -1 : this.highlightedRegionHint) + 1);
                } else if (e.which === 38) { //up
                    if (e.type !== 'keydown') return;
                    this.highlightRegionHint((this.highlightedRegionHint <= 0 ? $elem.find('.options li').length : this.highlightedRegionHint) - 1);
                } else if (e.which === 27) { //esc
                    if (e.type !== 'keydown') return;
                    $input.val($elem.attr('data-label')).blur();
                } else if (e.which === 13) { //enter
                    if (e.type !== 'keydown') return;
                    const options = $elem.find('.options li');
                    if (options.length) {
                        const item = $elem.find('.options li').eq(this.highlightedRegionHint > -1 ? this.highlightedRegionHint : 0);
                        $input.blur();
                        this.selectRegion(item.attr('data-value'), item.html());
                    }

                } else {
                    this.loadRegionHints($input.val());
                }
            }).on('blur keyup', () => {
                if (!$input.val() && $elem.attr('data-value')) {
                    this.selectRegion('', '');
                }
            });
        }

        this.highlightedRegionHint = -1;
        this.onChange = onChange;
    }

    loadRegionHints(search) {
        const { $elem } = this;

        clearTimeout(this.regionTimeout);
        if (search.length < 2) {
            $elem.removeClass('progress');
            $elem.find('.options').html('')
        } else {
            $elem.addClass('progress');
            this.regionTimeout = setTimeout(() => this._loadRegionHints(search), 200);
        }
    }

    _loadRegionHints(search) {
        const { $elem } = this;

        $elem.addClass('progress');
        this.highlightRegionHint(-1);
        $.get('/Region/' + search).then(result => {
            const options = result.map(o =>  `<li data-value="${o.value}">${o.label}</li>`).join('');
            $elem.find('.options').html(options);
            $elem.find('.options li').on('click', e => {
                const $el = $(e.currentTarget);
                this.selectRegion($el.attr('data-value'), $el.html());
            })
            $elem.removeClass('progress');
        })
    }

    selectRegion(id, label) {
        const { $elem, $input } = this;
        if (label) label = label.replace(/<span>.*?<\/span>/g, '').trim();
        this.highlightRegionHint(-1);
        $elem.attr('data-value', id);
        if (label) {
            $elem.attr('data-label', label);
            if ($input.attr("type") === "radio") {
                $elem.find('.region-search__value').html(label);
            }
        }
        clearTimeout(this.regionTimeout);
        $elem.removeClass('progress');
        $elem.find('.options').html('');
        if (label && $input.attr("type") !== "radio") $input.val(label);
        document.cookie = `selected_region=${id}; path=/;`;
        this.onChange && this.onChange();
    }

    highlightRegionHint(index) {
        const { $elem } = this;
        this.highlightedRegionHint = index;
        $elem.find('.options li.highlight').removeClass('highlight');
        $elem.find('.options li').eq(index).addClass('highlight');
    }
}