import 'select2'
import 'select2/dist/js/i18n/de'
import Chart from 'chart.js'
import moment from 'moment'
import Modal from './widgets/modal'
import RegionPicker from './widgets/region-picker'

const colors = {
    blue: '#739ef1',
    yellow: '#fdb85d',
    red: '#ff7373',
    neutral: '#35506d'
}

const colorsByMode = {
    clippings: colors.neutral,
    topics: colors.blue,
    companies: colors.yellow,
    references: colors.red
}

const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

const labels = {
    month: (from, to) => Array.apply(null, Array(12)).map((v, i) => months[(moment(to).month() - i + 12) % 12]).reverse(),
    week: (from, to) => Array.apply(null, Array(12)).map((v, i) => moment(to).week() - i + 12).reverse(),
    day: (from, to) => Array.apply(null, Array(12)).map((v, i) => days[(moment(to).day() + 14 - i) % 7]).reverse(),
}

const chartBaseOptions = {
    cornerRadius: 2,
    fullCornerRadius: false,
    responsive: true,
    layout: {
        padding: {
            left: -15,
        }
    },
    legend: {
        display: false
    },
    scales: {
        xAxes: [{
            ticks: {
                fontColor: '#828d99',
                fontSize: 11,
                fontFamily: 'Roboto, sans-serif',
                callback: function (value, index, values) {
                    return !value.substr ? value : value.substr(0, 1);
                }
            },
            gridLines: {
                display: false,
            }
        }],
        yAxes: [{
            ticks: {
                display: false
            },
            gridLines: {
                display: false
            }
        }]
    }
};

function makeChart(mode, data, labels) {
    const color = colorsByMode[mode] || colors.neutral;
    const elem = $(`#chart-${mode}`);
    return new Chart(elem, {
        type: 'bar',
        data: {
            labels,
            datasets: [{
                data,
                backgroundColor: color,
                hoverBackgroundColor: color,
                barThickness: 5
            }]
        },
        options: chartBaseOptions
    });
}

const plusify = v => v > 0 ? `+${v.toLocaleString()}` : v === 0 ? '' : v.toLocaleString();

function assignCurrentCount(data, mode) {
    const blank = !!$('#analytics-dashboard[data-blank]').length;
    let value = 0;
    if (data.length && (!blank || mode == 'overall')) {
         value = data.slice(-1)[0].toLocaleString()
    }
    $(`#current-count-${mode} .analytics-intro-item__value`)
        .html(value)
        .parent().removeClass('analytics-intro-item--progress');
}

function assignTotalCount(data, mode) {
    const blank = !!$('#analytics-dashboard[data-blank]').length;
    let value = 0;
    if (!blank || mode == 'overall') {
        value = data.reduce((agg, d) => agg + d, 0).toLocaleString()
    }
    $(`#total-count-${mode}`)
        .html(value)
        .parents('.analytics-card')
        .removeClass('analytics-card--progress');
}

function trimData(data, from, to) {
    return data.filter(d => moment(d.step.to).isAfter(from)).map(d => d.count)
}

function trimDay(data) {
    return data.length >= 2 ? data.slice(0, -2) : data;
}

function dashify(data, mode, from, to, step, trim = true) {

    if(trim) data = trimData(data, from, to);

    assignCurrentCount(data, mode);

    if (step === 'day') { //trim special 24h & b24h
        data = trimDay(data);
    }

    assignTotalCount(data, mode);

    const lbls = labels[step](from, to);

    makeChart(
        mode,
        data,
        lbls
    );
}

function hideHint(hint) {
    try {
        if (window.localStorage) {
            window.localStorage.setItem(`hide_hint_${hint}`, true);
            return;
        }
    } catch (e) {
        console.log(e);
    }

    var date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    document.cookie = `hide_hint_${hint}=true; path=/; expires=${date.toUTCString()}`;
}

function showHint(hint) {
    var result = false;
    try {
        if (window.localStorage) {
            result = window.localStorage.getItem(`hide_hint_${hint}`);
        }
    } catch (e) { }

    return !(result || document.cookie.indexOf(`hide_hint_${hint}`) >= 0);
}

function maybeShowHint(hint, callback) {
    let content;
    let title;
    const show = showHint(hint);
    if (show) {
        if (hint == "companies") {
            content = $(`<div>
                    <div class="modal-content__button-row">
                        <button id="hint-ok">OK</button>
                        <label>
                            <input type="checkbox" id="hint-check" />
                            Nicht mehr anzeigen
                        </label>
                    </div>
                </div>`);
            title = `So geht's: Klicken Sie auf <span class="icon-eye"></span> um Quellen zu Ihrem Monitoring hinzuzufügen`;
        } else if (hint == "references") {
            content = $(`<div>
                    <ol>
                        <li>Referenzwort als Thema anlegen</li>
                        <li>Angelegtes Thema mit <span class="icon-chat"></span> markieren</li>
                    </ol>
                    <div class="modal-content__button-row">
                        <button id="hint-ok">OK</button>
                        <label>
                            <input type="checkbox" id="hint-check" />
                            Nicht mehr anzeigen
                        </label>
                    </div>
                </div>`);
            title = "So kennzeichnen Sie ein Thema als Referenz";
        }
    }

    if (content && title) {
        const modal = new Modal(() => {
            if ($('#hint-check').is(':checked')) {
                hideHint(hint);
            }
            callback && callback();
        }).show(content, title);
        content.find("#hint-ok").on('click', () => {
            modal.close();
            return false;
        })
    }

    return show;
}

function maybeShowDashboardReloadHint() {
    if ($('#analytics-dashboard').length) {
        $('.analytics-reload-hint').addClass('analytics-reload-hint--show');
    }
}

$(() => {

    $('#mailing_interval').select2({
        width: '100%',
        minimumResultsForSearch: -1
    });

    $('#mailing_day').select2({
        width: '100%',
        minimumResultsForSearch: -1
    });

    $('#mailing_hour').select2({
        width: '100%',
        minimumResultsForSearch: -1
    });

    $('#news_mailing_interval').select2({
        width: '100%',
        minimumResultsForSearch: -1
    });

    $('#news_mailing_day').select2({
        width: '100%',
        minimumResultsForSearch: -1
    });

    $('#news_mailing_hour__').select2({
        width: '100%',
        minimumResultsForSearch: -1,
        multiple: true
    });

    $('.form-group[data-show], .form-fieldset[data-show]').hide().each((idx, el) => {
        const $el = $(el);
        const rules = JSON.parse($el.attr('data-show'));
        if (rules.field && rules.condition) {
            const $field = $(rules.field);
            const checkShow = () => {
                let show = true;
                if (rules.condition.startsWith("!")) {
                    show = $field.val() !== rules.condition.slice(1);
                } else {
                    show = $field.val() === rules.condition;
                }

                if (show) {
                    $el.find('[data-required]').each((id, item) => {
                        $(item).attr("required", true)
                    })
                } else {
                    $el.find('[required]').each((id, item) => {
                        $(item).attr("data-required", true)
                        $(item).attr("required", false)
                    })
                }

                $el.toggle(show)
            }
            $field.on('change', checkShow);
            checkShow();
        }
    });

    var monitoringListReloadTimeout = -1;
    var monitoringListReload = null
    $('#monitoring-list-filter').on('keyup change', e => {
        const search = e.target.value;
        /*clearTimeout(monitoringListReloadTimeout);
        monitoringListReloadTimeout = setTimeout(() => {
            if (monitoringListReload) monitoringListReload.abort();
            monitoringListReload = $.get(`${window.location.pathname}${e.target.value}`).done(data => {
                $('#monitoring-list').html(data);
            })
        }, 100);*/
        if (search) {
            $('#monitoring-list li').each((idx, elem) => {
                $(elem).toggleClass(
                    'analytics-list__item--hide',
                    $(elem).find('.analytics-list__label').text().toLowerCase().indexOf(search.toLowerCase()) < 0
                );
            })
        } else {
            $('#monitoring-list li').removeClass('analytics-list__item--hide');
        }
    })

    $('.analytics-list__item[data-load]').each((idx, elem) => {
        const url = $(elem).attr('data-load');
        $.get(url).then(data => {
            $(data).insertAfter(elem);
            const context = $(elem).parent();
            
            $('[data-action="save-search"]', context).on('click', (ev) => {
                const target = ev.currentTarget;
                const $target = $(target);
                const search = $target.attr('data-search');
                if (target.disabled || !search) return false;

                if ($target.attr("data-on") !== undefined) {
                    const id = $target.parents('.analytics-list__item').attr("data-id");
                    if (!id) { return false }
                    $.post(`/User/Search/Delete/${encodeURIComponent(id)}`).then(result => {
                        if (!result.error) {
                            $target.attr("data-on", null);
                            $target.attr("title", $target.attr("data-title-off"));
                            $target.parents('.analytics-list__item').addClass('analytics-list__item--uncovr').attr('data-id', null);
                            maybeShowDashboardReloadHint();
                        } else {
                            new Modal().show(result.msg);
                        }
                    });
                } else {
                    $.post(`/User/Search/Save/${encodeURIComponent(search)}/Alle`).then(result => {
                        if (!result.error) {
                            $target.attr("data-on", true);
                            $target.attr("title", $target.attr("data-title-on"));
                            $target.parents('.analytics-list__item').removeClass('analytics-list__item--uncovr').attr('data-id', result.id);
                            maybeShowDashboardReloadHint();
                        } else {
                            new Modal().show(result.msg);
                        }
                    });
                }
                return false;
            }).each((idx, el) => {
                $(el).attr("title", $(el).attr(`data-title-${$(el).attr("data-on") !== undefined ? "on" : "off"}`))
            });
            
            $('[data-action="subscribe"]', context).click((ev) => {
                const target = ev.currentTarget;
                const $target = $(target);
                const item = $target.parents('[data-id]');
                const id = item.attr('data-id');

                if (target.disabled || !id) { return false; }

                if ($target.attr("data-on") !== undefined) {
                    $.post('/User/Unsubscribe/' + id).then(result => {
                        if (!result.error) {
                            item.addClass('analytics-list__item--uncovr')
                            $target.attr("data-on", null);
                            $target.attr("title", $target.attr("data-title-off"));
                            maybeShowDashboardReloadHint();
                        } else {
                            new Modal().show(result.msg);
                        }
                    });
                } else {
                    $.post('/User/Subscribe/' + id).then(result => {
                        if (!result.error) {
                            item.removeClass('analytics-list__item--uncovr')
                            $target.attr("data-on", true);
                            $target.attr("title", $target.attr("data-title-on"));
                            maybeShowDashboardReloadHint();
                        } else {
                            new Modal().show(result.msg);
                        }
                    });
                }
                return false;
            }).each((idx, el) => {
                $(el).attr("title", $(el).attr(`data-title-${$(el).attr("data-on") !== undefined ? "on":"off"}`))
            });

            $(elem).remove();
        })
    })

    if ($('#analytics-dashboard').length) {
        const blank = !!$('#analytics-dashboard[data-blank]').length;
        const step = $('.analytics-interval-select').attr('data-value') || 'month';

        const to = step === 'day' ? moment() : moment().endOf(step);
        const from = moment(to).add(-12, step);

        const overall = $.get(`/Stats/overall/${from.format('DD-MM-YYYY HH[h]mm[m]ss')}/${to.format('DD-MM-YYYY HH[h]mm[m]ss')}/${step}`);
        const topics = blank ? overall : $.get(`/Stats/topics/${from.format('DD-MM-YYYY HH[h]mm[m]ss')}/${to.format('DD-MM-YYYY HH[h]mm[m]ss')}/${step}`);
        const companies = blank ? overall : $.get(`/Stats/companies/${from.format('DD-MM-YYYY HH[h]mm[m]ss')}/${to.format('DD-MM-YYYY HH[h]mm[m]ss')}/${step}`);
        const references = blank ? overall : $.get(`/Stats/references/${from.format('DD-MM-YYYY HH[h]mm[m]ss')}/${to.format('DD-MM-YYYY HH[h]mm[m]ss')}/${step}`);

        $.when(overall).then(data => dashify(data, 'overall', from, to, step))
        $.when(topics).then(data => dashify(data, 'topics', from, to, step))
        $.when(companies).then(data => dashify(data, 'companies', from, to, step))
        $.when(references).then(data => dashify(data, 'references', from, to, step))

        $.when(topics, companies, references).then((topicData, companyData, referenceData) => {

            topicData = trimData(topicData[0] || [], from, to);
            companyData = trimData(companyData[0] || [], from, to);
            referenceData = trimData(referenceData[0] || [], from, to);
            const combinedData = topicData.map((d, idx) => d + (companyData[idx] || 0) + (referenceData[idx] || 0));

            $('#current-count-clippings .analytics-intro-item__value')
                .html(!blank && combinedData.length ? combinedData.slice(-1)[0].toLocaleString() : '0')
                .parent().removeClass('analytics-intro-item--progress');

            if (step === 'day') { //trim special 24h & b24h
                topicData = trimDay(topicData);
                companyData = trimDay(companyData);
                referenceData = trimDay(referenceData);
            }

            $('#total-count-clippings')
                .html(blank? 0 : combinedData.reduce((agg, d) => agg + d, 0).toLocaleString())
                .parents('.analytics-card')
                .removeClass('analytics-card--progress');

            const lbls = labels[step](from, to);

            new Chart($('#chart-clippings'), {
                type: 'bar',
                data: {
                    labels: lbls,
                    datasets: [{
                        label: 'Themen',
                        data: topicData,
                        backgroundColor: colors.blue,
                        hoverBackgroundColor: colors.blue,
                        barThickness: 15
                    }, {
                        label: 'Quellen',
                        data: companyData,
                        backgroundColor: colors.yellow,
                        hoverBackgroundColor: colors.yellow,
                        barThickness: 15
                    }, {
                        label: 'Referenzen',
                        data: referenceData,
                        backgroundColor: colors.red,
                        hoverBackgroundColor: colors.red,
                        barThickness: 15
                    }]
                },
                options: {
                    cornerRadius: 4,
                    fullCornerRadius: false,
                    responsive: true,
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 5,
                            usePointStyle: true,
                            fontColor: '#828d99',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            padding: 30,
                        }
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontColor: '#828d99',
                                fontSize: 11,
                                fontFamily: 'Roboto, sans-serif',
                                callback: function (value, index, values) {
                                    return value.substr ? value.substr(0, step === 'day' ? 2 : 3) : value;
                                }
                            },
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                min: 0,
                                fontColor: '#828d99',
                                fontSize: 11,
                                fontFamily: 'Roboto, sans-serif',
                            },
                            gridLines: {
                                display: true,
                                drawBorder: false,
                                drawTicks: false,
                                color: '#eaeaea',
                                zeroLineColor: '#eaeaea',
                            }
                        }]
                    }
                }
            });
        })
    }

    if ($('#analytics-overview').length) {
        const to = moment().endOf('week');
        const from = moment(to).add(-12, 'week');

        const mode = $('#analytics-overview').attr('data-mode') || "clippings";


        if (mode === 'clippings') {
            const topics = $.get(`/Stats/topics/${from.format('DD-MM-YYYY HH[h]mm[m]ss')}/${to.format('DD-MM-YYYY HH[h]mm[m]ss')}/week`);
            const companies = $.get(`/Stats/companies/${from.format('DD-MM-YYYY HH[h]mm[m]ss')}/${to.format('DD-MM-YYYY HH[h]mm[m]ss')}/week`);
            const references = $.get(`/Stats/references/${from.format('DD-MM-YYYY HH[h]mm[m]ss')}/${to.format('DD-MM-YYYY HH[h]mm[m]ss')}/week`);

            $.when(topics, companies, references).then((topicData, companyData, referenceData) => {

                topicData = trimData(topicData[0] || [], from, to);
                companyData = trimData(companyData[0] || [], from, to);
                referenceData = trimData(referenceData[0] || [], from, to);
                const combinedData = topicData.map((d, idx) => d + (companyData[idx] || 0) + (referenceData[idx] || 0));

                dashify(combinedData, 'clippings', from, to, 'week', false);
            })
        } else {
            const data = $.get(`/Stats/${mode}/${from.format('DD-MM-YYYY HH[h]mm[m]ss')}/${to.format('DD-MM-YYYY HH[h]mm[m]ss')}/week`);
            $.when(data).then(data => dashify(data, mode, from, to, 'week'))
        }
    }

    if ($('[data-maybe-show-hint]').length) {
        const hint = $('[data-maybe-show-hint]').attr('data-maybe-show-hint');
        maybeShowHint(hint);
    }

    $('[data-maybe-show-hint-click]').on('click', ev => {
        const hint = $(ev.target).attr('data-maybe-show-hint-click');
        return !maybeShowHint(hint, () => {
            const href = $(ev.target).attr('href');
            if (href) {
                window.location = $(ev.target).attr('href');
            }
        });
    });

    $('[data-show-addmodal]').on('click', ev => {
        const type = $(ev.target).attr('data-show-addmodal');
        let content;
        let title;
        let location;
        if (type == "topics") {
            content = $(`<div>
                <div class="form no-pad mv1">
                    <input type="text" id="add-input" placeholder="Thema eingeben" autocomplete="off" value="" style="width: 100%" />
                </div>
                <div class="modal-content__button-row">
                    <button id="add-ok">Ergebnis prüfen</button>
                </div>
            </div>`);
            title = "Neues Thema hinzufügen";
            location = "/Themen-auswählen/"
        } else if (type == "references") {
            content = $(`<div>
                <div class="form no-pad mv1">
                    <input type="text" id="add-input" placeholder="Referenz eingeben" autocomplete="off" value="" style="width: 100%" />
                </div>
                <div class="modal-content__button-row">
                    <button id="add-ok">Ergebnis prüfen</button>
                </div>
            </div>`);
            title = "Neue Referenz hinzufügen";
            location = "/Referenz-auswählen/"
        } else if (type == "companies") {
            content = $(`<div>
                <div class="form no-pad mv1">
                    <select id="add-input" style="width: 100%"></select>
                </div>
                <div class="modal-content__button-row" id="default-row">
                    <button id="add-direct">Zu Monitoring hinzufügen</button>
                </div>
                <div class="modal-content__button-row" id="email-row" style="display: none;">
                    <a class="button" href="mailto:hello@uncovr.com?subject=Quelle%20vorschlagen">Neue Quelle vorschlagen</a>
                </div>
                <div class="modal-content__overlay" style="visibility: hidden; opacity: 0;"><strong>Quelle hinzugefügt</strong></div>
            </div>`);
            title = "Neue Quelle hinzufügen";
            location = "/Quellen/"
        }

        if (content && title) {
            const modal = new Modal().show(content, title);
            const submit = () => {
                let search = content.find("#add-input").val();
                if (search) {
                    if (type == "references" && !~search.indexOf('"')) {
                        search = `"${search}"`;
                    }
                    window.location = location + encodeURIComponent(search);
                }
                modal.close();
            }
            if (type === "companies") {
                $('#add-input', content).select2({
                    width: '100%',
                    minimumInputLength: 2,
                    language: 'de',
                    placeholder: 'Firmenname oder Marke eingeben',
                    multiple: true,
                    templateResult: (data, option) => {
                        if (data.selected) {
                            option.setAttribute("aria-selected", "true");
                            option.setAttribute("data-selected", "true");
                        }
                        option.innerHTML = data.text;

                        return option;
                    },
                    ajax: {
                        dataType: 'json',
                        delay: 200,
                        url: params => `/Newsrooms/AutoComplete/${encodeURIComponent(params.term)}/`,
                        processResults: (data, params) => {
                            return {
                                results: [...data.map(o => ({
                                    id: o.slug,
                                    text: o.label,
                                    selected: o.subscribed
                                })), {
                                    id: "__not_found__",
                                    text: "Nichts passendes gefunden"
                                }]
                            }
                        }
                    },
                }).on("change", () => {
                    const val = $('#add-input', content).val();
                    $('#default-row', content).toggle(val !== "__not_found__");
                    $('#email-row', content).toggle(val === "__not_found__");
                    $('#add-ok', content).attr('disabled', val.length > 1)
                }).select2('open');
                //.data('select2').$dropdown.addClass("select2-dropdown--overlay-box");
                content.find("#add-direct").on('click', e => {
                    const button = e.currentTarget;
                    $(button).addClass("loading loading--left");
                    $.when(...content.find("#add-input").val().map(id => {
                        id = id.split('-')[0];
                        if (!id) return;
                        return $.post('/User/Subscribe/' + id);
                    }).filter(Boolean)).then((...result) => {
                        const data = (result[0].length) ? result.map(r => r[0]) : result[0];
                        $(button).removeClass("loading loading--left");
                        maybeShowDashboardReloadHint();
                        $('#add-input', content).val(null).trigger('change');
                        content.find(".modal-content__overlay").css({ opacity: 1, visibility: "visible" });
                        setTimeout(() => {
                            content.find(".modal-content__overlay").css({ opacity: 0, visibility: "hidden" });
                            $('#add-input', content).select2('open');
                        }, 800)

                        const errors = data.filter(d => d.error);
                        if(errors.length) {
                            new Modal().show(errors[0].msg);
                        }
                    })

                    return false;
                })
            }
            content.find("#add-input").on("keyup", e => {
                if (e.key == "Enter") {
                    submit();
                }
            }).focus();
            content.find("#add-ok").on('click', () => {
                submit();
                return false;
            })
            return false;
        }
    });

    if ($('.analytics-info__hero').length) {
        const updatePosition = () => {
            $('.analytics-info__hero').css('transform', `translateY(${window.scrollY * -.25}px)`);
            $('.analytics-info__hero .backdrop').css('transform', `translateY(${window.scrollY * .125}px) scaleY(-1)`);
        }
        window.addEventListener('scroll', () => {
            updatePosition();
        }, { passive: true });
        updatePosition();
    }

    if ($('.analytics-info__parallax').length) {
        const updatePosition = () => {
            $('.analytics-info__parallax [data-parallax]').each((idx, el) => {
                $(el).css('transform', `translateY(${(window.scrollY - $('.analytics-info__parallax').offset().top + window.innerHeight * 0.5) * parseFloat($(el).attr('data-parallax'))}px)`);
            })
        }
        window.addEventListener('scroll', () => {
            updatePosition();
        }, { passive: true });
        updatePosition();
    }

    const $regionSearch = $('#monitoring-region-search');
    new RegionPicker($regionSearch, () => {
        const value = $regionSearch.attr("data-value");
        if (value) {
            window.location = $regionSearch.attr('data-base').replace("1234", $regionSearch.attr("data-value"))
        } else {
            window.location = $regionSearch.attr('data-base').replace("region-1234", '')
        }
    });
});



/* *
 * Rounded border bar graph
 * https://jsfiddle.net/uffo/5oty49r3/6/
 * */
Chart.elements.Rectangle.prototype.draw = function () {
    var ctx = this._chart.ctx;
    var vm = this._view;
    var left, right, top, bottom, signX, signY, borderSkipped;
    var borderWidth = vm.borderWidth;

    // If radius is less than 0 or is large enough to cause drawing errors a max
    //      radius is imposed. If cornerRadius is not defined set it to 0.
    var cornerRadius = this._chart.config.options.cornerRadius;
    var fullCornerRadius = this._chart.config.options.fullCornerRadius;
    var stackedRounded = this._chart.config.options.stackedRounded;
    var typeOfChart = this._chart.config.type;

    if (cornerRadius < 0) {
        cornerRadius = 0;
    }
    if (typeof cornerRadius == 'undefined') {
        cornerRadius = 0;
    }
    if (typeof fullCornerRadius == 'undefined') {
        fullCornerRadius = true;
    }
    if (typeof stackedRounded == 'undefined') {
        stackedRounded = false;
    }

    if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
    } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        var halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
        }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    var corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom]
    ];

    // Find first (starting) corner with fallback to 'bottom'
    var borders = ['bottom', 'left', 'top', 'right'];
    var startCorner = borders.indexOf(borderSkipped, 0);
    if (startCorner === -1) {
        startCorner = 0;
    }

    function cornerAt(index) {
        return corners[(startCorner + index) % 4];
    }

    // Draw rectangle from 'startCorner'
    var corner = cornerAt(0);
    ctx.moveTo(corner[0], corner[1]);


    var nextCornerId, nextCorner, width, height, x, y;
    for (var i = 1; i < 4; i++) {
        corner = cornerAt(i);
        nextCornerId = i + 1;
        if (nextCornerId === 4) {
            nextCornerId = 0
        }

        nextCorner = cornerAt(nextCornerId);

        width = corners[2][0] - corners[1][0];
        height = corners[0][1] - corners[1][1];
        x = corners[1][0];
        y = corners[1][1];

        var radius = cornerRadius;
        // Fix radius being too large
        if (radius > Math.abs(height) / 2) {
            radius = Math.floor(Math.abs(height) / 2);
        }
        if (radius > Math.abs(width) / 2) {
            radius = Math.floor(Math.abs(width) / 2);
        }

        var x_tl, x_tr, y_tl, y_tr, x_bl, x_br, y_bl, y_br;
        if (height < 0) {
            // Negative values in a standard bar chart
            x_tl = x;
            x_tr = x + width;
            y_tl = y + height;
            y_tr = y + height;

            x_bl = x;
            x_br = x + width;
            y_bl = y;
            y_br = y;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);

            ctx.lineTo(x_br - radius, y_br);

            // bottom right
            ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);


            ctx.lineTo(x_tr, y_tr + radius);

            // top right
            fullCornerRadius ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr) : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);


            ctx.lineTo(x_tl + radius, y_tl);

            // top left
            fullCornerRadius ? ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius) : ctx.lineTo(x_tl, y_tl, x_tl, y_tl + radius);


            ctx.lineTo(x_bl, y_bl - radius);

            //  bottom left
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);

        } else if (width < 0) {
            // Negative values in a horizontal bar chart
            x_tl = x + width;
            x_tr = x;
            y_tl = y;
            y_tr = y;

            x_bl = x + width;
            x_br = x;
            y_bl = y + height;
            y_br = y + height;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);

            ctx.lineTo(x_br - radius, y_br);

            //  Bottom right corner
            fullCornerRadius ? ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius) : ctx.lineTo(x_br, y_br, x_br, y_br - radius);

            ctx.lineTo(x_tr, y_tr + radius);

            // top right Corner
            fullCornerRadius ? ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr) : ctx.lineTo(x_tr, y_tr, x_tr - radius, y_tr);

            ctx.lineTo(x_tl + radius, y_tl);

            // top left corner
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);

            ctx.lineTo(x_bl, y_bl - radius);

            //  bttom left corner
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);

        } else {

            var lastVisible = 0;
            for (var findLast = 0, findLastTo = this._chart.data.datasets.length; findLast < findLastTo; findLast++) {
                if (!this._chart.getDatasetMeta(findLast).hidden) {
                    lastVisible = findLast;
                }
            }
            var rounded = this._datasetIndex === lastVisible;

            if (rounded) {
                //Positive Value
                ctx.moveTo(x + radius, y);

                ctx.lineTo(x + width - radius, y);

                // top right
                ctx.quadraticCurveTo(x + width, y, x + width, y + radius);


                ctx.lineTo(x + width, y + height - radius);

                // bottom right
                if (fullCornerRadius || typeOfChart == 'horizontalBar')
                    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
                else
                    ctx.lineTo(x + width, y + height, x + width - radius, y + height);


                ctx.lineTo(x + radius, y + height);

                // bottom left
                if (fullCornerRadius)
                    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                else
                    ctx.lineTo(x, y + height, x, y + height - radius);


                ctx.lineTo(x, y + radius);

                // top left
                if (fullCornerRadius || typeOfChart == 'bar')
                    ctx.quadraticCurveTo(x, y, x + radius, y);
                else
                    ctx.lineTo(x, y, x + radius, y);
            } else {
                ctx.moveTo(x, y);
                ctx.lineTo(x + width, y);
                ctx.lineTo(x + width, y + height);
                ctx.lineTo(x, y + height);
                ctx.lineTo(x, y);
            }
        }

    }

    ctx.fill();
    if (borderWidth) {
        ctx.stroke();
    }
};